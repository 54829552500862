import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import { uri } from "../../../../constants/API_URLS";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { notify } from "../../../../utils/helpers";
import { cloneObj } from "../../../../shared/utils";
import { rejectedReasons, statusApproval } from "../../../../utils/Data";

const Implemented = ({ data, setChangedData, id, view, PQCDSM }) => {
  const notificationAlertRef = useRef(null);
  const { t } = useTranslation();
  const [beforeFile, setBeforeFile] = useState();
  const [afterFile, setAfterFile] = useState();
  const [savings, setSavings] = useState(0);
  const [cost, setCost] = useState(0);
  const [impact, setImpact] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [approved, setApproved] = useState(data[1]?.accepted || true);
  const [holdReason, setHoldReason] = useState(!view && data[1]?.accepted === 'hold' ? data[1]?.reasonOfHold : '');
  const [rejectedReason, setRejectedReason] = useState('High cost');
  const [other, setOther] = useState('');

  const saveChangesHandler = () => {
    notify(
      "success",
      'Your changes have been saved.',
      notificationAlertRef,
      'Saved Successfully'
    );
    const implementedChanges = {
      before: beforeFile,
      after: afterFile,
      savings,
      cost,
      roi: savings / cost,
      value: true,
      date: new Date(),
      accepted: approved,
      reasonOfHold: holdReason,
      reasonOfDenial: rejectedReason === "Other" ? other : approved && rejectedReason,
    }
    if ((/hold/i).test(approved)) {
      delete implementedChanges.value;
      delete implementedChanges.date;
    }

    setChangedData({
      data: {
        implemented:
          implementedChanges,
      },
      key: 'implemented',
      type: 'status',
      id: id
    });
  }
  return (
    <Card className="w-100 p-3" key={data[0]}>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <CardHeader>
        <p className="h3 text-capitalize">{t("implemented.implemented")}</p>
      </CardHeader>
      <Row className="w-100">

        {/* approved*/}
        <Col className="m-5" md="4">
          <FormGroup>
            <p className="h3">Evaluation decision</p>
            <Input
              type="select"
              disabled={view}
              value={approved}
              onChange={(e) => {
                setApproved(e.target.value);
                setDataChanged(true);
              }}
            >
              {statusApproval.map(el => <option key={el.label} value={el.value}>{t(`approved.${el.label}`)}</option>)}
            </Input>
          </FormGroup>
        </Col>

        {/* hold reason*/}
        {(/hold/i).test(approved) && <Col className="m-5" md="4">
          <p className="h3">{t('evaluated.hold reason')}</p>
          <Input
            placeholder={t('evaluated.hold reason')}
            value={holdReason}
            onChange={(e) => setHoldReason(e.target.value)}
            type="text"
          />
        </Col>}
        {/* rejected reason*/}
        {(/false/i).test(approved) && <Col className="m-5" md="4">
          <FormGroup>
            <p className="h3">{t('evaluated.rejected reason')}</p>
            <Input
              type="select"
              disabled={view}
              value={rejectedReason}
              onChange={(e) => setRejectedReason(e.target.value)}
            >
              {rejectedReasons.map(el => <option key={el.label} value={el.value}>{t(`approved.${el.label}`)}</option>)}
            </Input>
          </FormGroup>
        </Col>}

        {/* other */}
        {((/false/i).test(approved) && rejectedReason === 'Other') && <Col className="m-5" md="4">
          <p className="h3">{t('evaluated.Other')}</p>
          <Input
            placeholder="Other"
            disabled={view}
            value={other}
            onChange={(e) => {
              setOther(e.target.value)
            }}
            type="text"
          />
        </Col>}

        {(data[1].value || (/true/i.test(data[1].accepted)) || (/true/i).test(approved)) && (
          <>
            <Col className="m-5" md="4">
              <p className="h3">{t("implemented.Savings")}</p>
              <input
                className="form-control"
                type="number"
                value={view ? data[1]?.savings : savings}
                disabled={view}
                min={0}
                onChange={(e) => {
                  setDataChanged(true);
                  setSavings(+e.target.value)
                }}
                placeholder={t("implemented.Savings")}
              />
            </Col>
            <Col className="m-5" md="4">
              <p className="h3">{t("implemented.Cost")}</p>
              <input
                className="form-control"
                type="number"
                value={view ? data[1]?.cost : cost}
                min={0}
                disabled={view}
                onChange={(e) => {
                  setDataChanged(true);
                  setCost(+e.target.value)
                }}
                placeholder={t("implemented.Cost")}
              />
            </Col>
            {PQCDSM?.map((el, index) => (
              <Col className="m-5" md="4" key={el}>
                <p className="h3">{`Impact on ${el} %`}</p>
                <input
                  className="form-control"
                  type="number"
                  value={
                    view ? data[1]?.PQCDSM_Impact[index]?.value : impact[index]?.value
                  }
                  min={0}
                  max={100}
                  disabled={view}
                  onChange={(e) => {
                    let value;
                    if (e.target.value > 100) {
                      value = 100;
                    } else if (e.target.value < 0) {
                      value = 0;
                    } else {
                      value = e.target.value;
                    }
                    const newArr = cloneObj(impact);
                    newArr[index] = {
                      key: PQCDSM[index],
                      value,
                    };
                    setImpact(newArr);
                  }}
                  placeholder={`Impact on ${el}`}
                />
              </Col>
            ))}
            {!data[1].value && (
              <Col className="m-5" md="4">
                <label htmlFor="imgBefore">{t("implemented.Before")}</label>
                <input
                  type="file"
                  className="form-control-file"
                  id="imgBefore"
                  disabled={view}
                  onChange={(e) => {
                    setDataChanged(true);
                    setBeforeFile(e.target.files[0])
                  }}
                />
                <img
                  style={{
                    paddingRight: "5px",
                    marginTop: "10px",
                    display: "block",
                  }}
                  src={uri + "/file/" + data[1].before?._id}
                  width="250px"
                  alt="Before"
                />
              </Col>
            )}
            {data[1].value && (
              <Col className="m-5" md="4">
                <label htmlFor="imgBefore">{t("implemented.Before")}</label>
                <input
                  type="file"
                  className="form-control-file"
                  id="imgBefore"
                  disabled={view}
                  onChange={(e) => {
                    setDataChanged(true);
                    setBeforeFile(e.target.files[0])
                  }}
                />
                {data[1]?.before && (
                  <img
                    id="imgBeforeWithView"
                    style={{
                      paddingRight: "5px",
                      marginTop: "10px",
                    }}
                    src={uri + "/file/" + data[1]?.before._id}
                    width="250px"
                    alt="Before"
                  />
                )}
              </Col>
            )}

            <Col className="m-5" md="4">
              <label htmlFor="imgAfter">{t("implemented.After")}</label>
              <input
                type="file"
                className="form-control-file"
                id="imgAfter"
                disabled={view}
                onChange={(e) => {
                  setDataChanged(true);
                  setAfterFile(e.target.files[0])
                }}
              />
              {data[1]?.after && (
                <img
                  style={{
                    paddingRight: "5px",
                    marginTop: "10px",
                  }}
                  src={uri + "/file/" + data[1]?.after._id}
                  width="250px"
                  alt="After"
                />
              )}
            </Col>
          </>
        )}
      </Row>
      <div className="text-center">
        {!view && <Button className="my-4" color="primary" type="button" onClick={saveChangesHandler} disabled={!dataChanged || view} >
          {t('evaluated.saveChanges')}
        </Button>}
      </div>
    </Card>
  );
};

export default React.memo(Implemented);