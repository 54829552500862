import { createSlice, current } from "@reduxjs/toolkit";
import {
  addEmployeeSheet,
  deleteEmployeeSheet,
  editEmployeeSheet,
  getEmployeeSheets,
} from "./EmployeeSheetActions";
const initialState = {
  EmployeeSheets: [],
};
const EmployeeSheetSlice = createSlice({
  name: "EmployeeSheet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmployeeSheets.fulfilled, (state, action) => {
      state.EmployeeSheets = action.payload;
    });
    builder.addCase(addEmployeeSheet.fulfilled, (state, action) => {
      const newState = current(state).push(action.payload);
      state.EmployeeSheets = newState;
    });
    builder.addCase(deleteEmployeeSheet.fulfilled, (state, action) => {
      const newState = current(state).EmployeeSheets.filter(
        (AICMinibusiness) => AICMinibusiness._id !== action.payload
      );
      state.EmployeeSheets = newState;
    });
    builder.addCase(editEmployeeSheet.fulfilled, (state, action) => {
      const newState = current(state).EmployeeSheets.map((AICMinibusiness) => {
        if (AICMinibusiness._id === action.payload._id) {
          return action.payload;
        } else {
          return AICMinibusiness;
        }
      });
      state.EmployeeSheets = newState;
    });
  },
});
export default EmployeeSheetSlice.reducer;
